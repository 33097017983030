<script>
import { Html5Qrcode } from 'html5-qrcode'

export default {
  name: 'POCCodigoDeBarras',
  data() {
    return {
      resultado: '', // Resultado do código
    }
  },
  mounted() {
    this.iniciarLeitor()
  },
  methods: {
    iniciarLeitor() {
      const html5QrCode = new Html5Qrcode('reader') // ID do elemento onde será exibido o scanner
      const config = { fps: 10, qrbox: { width: 250, height: 250 } }

      html5QrCode
        .start(
          { facingMode: 'environment' }, // Use a câmera traseira
          config,
          (decodedText, decodedResult) => {
            // Quando um código é detectado
            this.resultado = decodedText // Armazena o valor lido
            console.log('Decoded result', decodedResult)
          },
        )
        .catch(err => {
          console.error('Erro ao iniciar o leitor:', err)
        })
    },
  },

}
</script>

<template>
  <div class="text-center">
    <h2>Leitor de Código de Barras e QR Code</h2>
    <div id="reader" style="width: 100%;"></div>
    <h4><strong>Resultado:</strong></h4>
    <br>
    {{ resultado }}
  </div>
</template>

<style scoped>
#reader {
  margin: 20px auto;
  text-align: center;
  max-width: 500px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}
</style>
